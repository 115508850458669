import React from 'react'
import PropTypes from 'prop-types'

import SEO from '../../components/atoms/Seo'
import Footer from '../../components/organisms/Footer'
import Navbar from '../../components/organisms/Navbar'
import HomeAds from '../../components/organisms/HomeAds'
import Categories from '../../components/organisms/Categories'
import SiteSection from '../../components/atoms/SiteSection'
// import HomeReviews from '../../components/organisms/HomeReviews'
import PurchaseNeuf from '../../components/organisms/PurchaseNeuf'
// import PurchaseSteps from '../../components/organisms/PurchaseSteps'
import PurchaseSearch from '../../components/organisms/PurchaseSearch'
import PurchaseService from '../../components/organisms/PurchaseService'
import InlineBlockPresentation from '../../components/organisms/InlineBlockPresentation'

const TITLE = 'Acheter un local professionnel'
const DESCRIPTION = 'Acheter votre futur local professionnel'

const purchaseCategories = [{
  link: '/nos-annonces/acheter/?action=acheter',
  title: 'Achat Cabinet Médical',
  image: 'medical'
}, {
  link: '/nos-annonces/acheter/?action=acheter',
  title: 'Achat Cabinet Para-Médical',
  image: 'para-medical'
}, {
  link: '/nos-annonces/acheter/?action=acheter',
  title: 'Achat Local 2 pièces',
  image: 'local-t2'
}, {
  link: '/nos-annonces/acheter/?action=acheter',
  title: 'Achat Local 3 pièces',
  image: 'local-t3'
}, {
  link: '/nos-annonces/acheter/?action=acheter',
  title: 'Achat Local Paris',
  image: 'paris'
}, {
  link: '/nos-annonces/acheter/?action=acheter',
  title: 'Achat Local Lyon',
  image: 'lyon'
}, {
  link: '/nos-annonces/acheter/?action=acheter',
  title: 'Achat Local Montpellier',
  image: 'montpellier'
}, {
  link: '/nos-annonces/acheter/?action=acheter',
  title: 'Achat Local Nantes',
  image: 'nantes'
}]

const purchaseBlocs = [{
  html: 'Trouver Mon Local Pro est le 1er site à rassembler les offres de locaux à destination des professions libérales. Lancez votre recherche en regardant les biens disponibles à la vente',
  link: '/nos-annonces/acheter/?action=acheter',
  title: 'Une plateforme dédiée',
  subtitle: 'aux locaux professionnels',
  image: 'purchase-1',
  button: 'Lancer ma recherche'
}, {
  html: 'L\'achat d\'un local professionnel est un processus long qui nécessite du temps et des conseils pour la recherche, le financement, l\'aménagement. Nos experts sont là pour vous accompagner. ',
  link: '/nos-annonces/demande-accompagnement',
  title: 'Se faire accompagner par',
  subtitle: 'un Pro',
  image: 'purchase-2',
  button: 'Échanger avec un Pro'
}]

const PurchasePage = ({ location }) => {
  return (
    <>
      <SEO
        title={TITLE}
        description={DESCRIPTION} />
      <Navbar location={location} />
      <PurchaseSearch />
      <SiteSection id='services-presentation' margin='100px 0 0 0'>
        <InlineBlockPresentation
          blocs={purchaseBlocs}
          title='Achetez votre futur local seul ou accompagné' />
      </SiteSection>
      <SiteSection id='votre-futur-local' margin='120px 0 0 0'>
        <PurchaseService />
      </SiteSection>
      <SiteSection id='dernieres-nouveautes' margin='120px 0 0 0'>
        <HomeAds type='ad-sale' title='Nos derniers locaux à vendre' />
      </SiteSection>
      {/* <SiteSection id='les-etapes-pour-acheter' margin='120px 0 0 0'>
        <PurchaseSteps />
      </SiteSection> */}
      <SiteSection id='tous-nos-locaux-pro-achat' margin='120px 0 0 0'>
        <Categories
          title='Tous nos locaux professionnels à acheter'
          categories={purchaseCategories} />
      </SiteSection>
      <SiteSection id='acheter-un-local-pro-neuf' margin='50px 0 80px 0'>
        <PurchaseNeuf />
      </SiteSection>
      {/* <SiteSection id='avis' margin='60px 0 80px 0 '>
        <HomeReviews title='Ils nous ont fait confiance' />
      </SiteSection> */}
      <Footer />
    </>
  )
}

PurchasePage.propTypes = {
  location: PropTypes.object.isRequired
}

export default PurchasePage
