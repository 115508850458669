import React from 'react'
import { get } from 'lodash'
import { useStaticQuery, graphql } from 'gatsby'

import ServicePresentation from '../ServicePresentation'

const purchaseData = {
  alt: 'Acheter votre futur local professionnel',
  title: 'Achetez votre futur local professionnel',
  content: `Nous le savons, les professionnels libéraux ont des agendas très chargés et la recherche d’un bien immobilier peut être une source importante de stress et demander énormément de temps.<br/>
  C’est pour cela que nos experts vous accompagne de la définition de vos besoins, en passant par l’évaluation de votre budget, jusqu’à la signature chez le notaire.<br/>
  Notre mission : que l’achat de votre local professionnel soit une réussite et un atout pour la poursuite de votre carrière.`,
  subtitle: 'Chez Trouver Mon Local Pro nous vous accompagnons de A à Z pour que vous puissiez trouver facilement et acheter vos futurs locaux.'
}

const PurchaseService = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
    placeholderImage: file(relativePath: {eq: "purchase/service.webp"}) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
        )
      }
    }
  }`)

  return (
    <ServicePresentation
      alt={get(purchaseData, 'alt')}
      data={placeholderImage}
      title={get(purchaseData, 'title')}
      content={get(purchaseData, 'content')}
      subtitle={get(purchaseData, 'subtitle')} />
  )
}

export default PurchaseService
