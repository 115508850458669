import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

import media from '../../../utils/media'
import SearchCity from '../../molecules/SearchCity'
import TopSection from '../../molecules/TopSection'
import BackgroundImage from '../../molecules/BackgroundImage'
import TopSectionContent from '../../molecules/TopSectionContent'

const BULLETS = [
  'Des annonces dédiées aux locaux professionnels',
  'Des agents spécialisés',
  'Un accompagnement de A à Z'
]

const StyledBackground = styled(BackgroundImage)`
  z-index: -1;
  display: none;

  ${media.greaterThan('xl')`
    display: block;
    height: 700px;
    top: 245px;
  `}
`

const PurchaseSearch = () => {
  const data = useStaticQuery(graphql`{
    placeholderImage: file(relativePath: {eq: "purchase/top-section.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
    <TopSection component={() =>
      <StyledBackground alt='Acheter un local professionnel' data={data} />}>
      <TopSectionContent
        title='Acheter un local professionnel n’a jamais été aussi simple'
        bullets={BULLETS}
        Button={() => <SearchCity action='acheter' />} />
    </TopSection>
  )
}

export default PurchaseSearch
