import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import LaunchButton from '../../atoms/LaunchButton'
import ButtonContainer from '../../atoms/ButtonContainer'
import ImageSection from '../../molecules/ImageSection'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const size = {
  lg: {
    height: '503'
  }
}

const PurchaseNeuf = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
    placeholderImage: file(relativePath: {eq: "purchase/purchase-neuf.webp"}) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
        )
      }
    }
  }`)

  return (
    <ImageSection
      alt='Acheter un local professionnel neuf'
      size={size}
      fixed={false}
      right={false}
      imageData={placeholderImage}
      maxText={580}>
      <HomeSectionTitle tag='h2'>
        Acheter un local professionnel neuf
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        Il existe des opportunités intéressantes en achetant des locaux professionnels neufs auprès des promoteurs immobiliers.
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        En effet, de nombreux programmes d’habitations proposent des logements qui peuvent se transformer en local professionnel.<br />
        Dans ce cas, il est possible d’adapter les logements en transformant le plan : création de bureaux ou cabinets, salle d’attente, salles de réunion, salle de repos.<br />
        Les logements neufs respectent également les dernières normes environnementales et d’accessibilité pour permettre aux Personnes à Mobilité Réduite de facilement accéder au batiment.<br />
        Grâce à nos partenariats avec de nombreux promoteurs français, nous proposons dans notre offre des locaux au sein de batiments neufs.<br />
        Vous pouvez identifiez dès maintenant ceux qui pourraient vous correspondre.
      </HomeSectionDescription>
      <ButtonContainer>
        <LaunchButton
          link='/nos-annonces/acheter/?action=acheter'
          title='Voir les locaux professionnels neufs'
          background='brightOrange' />
      </ButtonContainer>
    </ImageSection>
  )
}

export default PurchaseNeuf
